<div class="w-full content flex justify-between">
  <div class="flex items-center">
    <span class="route-name w-32 truncate">{{ name }}</span>
  </div>
  <div class="flex items-center justify-between">
    <!-- media button -->
    <button class="action-btn hover:bg-neutral-300 rounded h-8" title="open media panel" (click)="openPanel('media')"
      #mediaBtn>
      <mat-icon *ngIf="selectedMediaFile" svgIcon="feather:media-selected" class="ml-1 mr-1 mt-1"></mat-icon>
      <mat-icon *ngIf="!selectedMediaFile" svgIcon="feather:media" class="ml-1 mr-1 mt-1"></mat-icon>
    </button>
    <!-- broadcast button -->
    <button class="action-btn hover:bg-neutral-300 rounded h-8" title="open broadcast panel"
      (click)="openPanel('broadcast')" #broadcastBtn>
      <mat-icon *ngIf="selectedBroadcast" svgIcon="feather:broadcast-selected" class="ml-1 mr-1 mt-1"></mat-icon>
      <mat-icon *ngIf="!selectedBroadcast" svgIcon="feather:broadcast" class="ml-1 mr-1 mt-1"></mat-icon>
    </button>

    <!-- settings button -->
    <button class="action-btn hover:bg-neutral-300 rounded h-8" title="open settings panel"
      (click)="openPanel('settings')" #settingsBtn>
      <mat-icon svgIcon="feather:settings" class="ml-1 mr-1 mt-1 icon-size-5 text-neutral-100"></mat-icon>
    </button>
  </div>
</div>


<!-- Media panel -->
<ng-template #mediaPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-lg overflow-hidden shadow-lg bg-neutral-600">
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-between pt-4 px-4 text-on-primary">
      <div class="flex items-center justify-between w-full">
        <div class="font-semibold">Display</div>
        <button class="text-sm font-semibold text-primary disabled:text-neutral-200" [disabled]="!selectedMediaFile"
          (click)="clearCheckedMediaFile()">
          Clear Check
        </button>
      </div>
    </div>
    <!-- content -->
    <div class="panel-content h-80 overflow-scroll">

      <ng-container *ngIf="mediaList.length > 0">
        <mat-radio-group [(ngModel)]="selectedMediaFile">
          <div *ngFor="let mediaFile of mediaList; let i=index" class="flex justify-between items-center">
            <mat-radio-button class="flex justify-between items-center mb-4 media-radio-button " [value]="mediaFile"
              [checked]="selectedMediaFile && mediaFile.id === selectedMediaFile.id">
              <div class="flex justify-between items-center">
                <span class="w-60 pl-1 truncate">{{mediaFile.fileName}}</span>
              </div>
            </mat-radio-button>
            <button class="self-center w-5 h-5 mb-3 ml-2" (click)="removeMediaFile(mediaFile.id, i)"
              title="Remove media file">
              <mat-icon svgIcon="feather:x-circle" class="text-neutral-200 icon-size-4"></mat-icon>
            </button>
          </div>
        </mat-radio-group>
      </ng-container>


      <div class="rounded border-collapse w-32 border border-primary p-2">
        <input id="avatar-file-input" class="absolute h-0 w-0 opacity-0 invisible pointer-events-none" type="file"
          [multiple]="false" accept=".jpg,.jpeg,.png,.mp4,.zip"
          (change)="uploadMediaFile(mediaFileInput.files)" #mediaFileInput>
        <label class="flex items-center justify-center cursor-pointer text-primary" for="avatar-file-input" matRipple>
          <mat-icon class="text-primary disabled:text-stone-500" [svgIcon]="'heroicons_solid:upload'"></mat-icon>
          <span class="w-full">Upload File</span>
        </label>
      </div>

    </div>

  </div>
</ng-template>

<!-- broadcast panel -->
<ng-template #broadcastPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-lg overflow-hidden shadow-lg bg-neutral-600">
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-between pt-4 px-4 text-on-primary">
      <div class="flex items-center justify-between w-full">
        <div class="font-semibold">Broadcast</div>
        <button class="text-sm font-semibold  text-primary disabled:text-neutral-200" [disabled]="!selectedBroadcast"
          (click)="clearBroadcastCheck()">
          Clear Check
        </button>
      </div>
    </div>
    <!-- content -->
    <div class="panel-content">

      <mat-radio-group [(ngModel)]="selectedBroadcast">
        <div *ngFor="let broadcast of broadcastList; let i = index;" class="flex justify-between items-center">
          <mat-radio-button [value]="broadcast" [checked]="selectedBroadcast && broadcast.id === selectedBroadcast.id" [disabled]="!broadcast.content">
            <div class="flex justify-between items-center mb-4 border-[1px] rounded">
              <mat-icon class="ml-2 mr-2" svgIcon="feather:broadcast"></mat-icon>
              <ng-container *ngIf="broadcast.isNew">
                <textarea class="rounded w-60 bg-neutral-700 text-zinc-100 p-3 border-[1px]" placeholder="input text"
                  row="1" [(ngModel)]="broadcast.content" autocomplete="off" matTextareaAutosize></textarea>
              </ng-container>
              <ng-container *ngIf="!broadcast.isNew">
                <textarea class="rounded w-60 bg-neutral-700 p-3 border-[1px]" [value]="broadcast.content"
                  autocomplete="off" [readonly]="true" matTextareaAutosize></textarea>
              </ng-container>
            </div>
          </mat-radio-button>
          <button class="self-center w-5 h-5 mb-3 ml-2" (click)="removeBroadcast(broadcast.id, i)"
            title="Remove broadcast">
            <mat-icon svgIcon="feather:x-circle" class="text-neutral-200 icon-size-4"></mat-icon>
          </button>
        </div>
      </mat-radio-group>

      <button class="flex items-center justify-center gap-2 bg-neutral-400 hover:bg-neutral-300 p-2 rounded w-full"
        (click)="addBroadcastInput()">
        <mat-icon class="text-white" [svgIcon]="'mat_outline:add'"></mat-icon>
        Add
      </button>
    </div>

  </div>
</ng-template>

<!-- settings panel -->
<ng-template #settingPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-lg overflow-hidden shadow-lg bg-neutral-600">
    <!-- content -->
    <div class="panel-content">
      <div class="font-semibold">GOTO</div>
      <div class="grid grid-cols-3 gap-1">
        <section class="text-input text-sm">
          <label for="speed" class="block mb-1 text-gray-900 dark:text-white" >Speed(m/s)</label>
          <input id="speed"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            [(ngModel)]="gotoParameters.speed"
          />
        </section>
        <section class="text-input text-sm">
          <label for="bubbleSize" class="block mb-1 text-gray-900 dark:text-white" >Bubble Size(m)</label>
          <input id="bubbleSize"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            [(ngModel)]="gotoParameters.bubbleSize"
          />
        </section>
        <section class="text-input text-sm">
          <label for="behavior" class="block mb-1 text-gray-900 dark:text-white" >Behavior</label>
          <input id="behavior"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            [(ngModel)]="gotoParameters.behavior"
          />
        </section>
      </div>
      <div class="font-semibold mt-4 mb-1">CAMERA</div>
      <div class="grid grid-cols-2 gap-1" >
        <section class="text-input text-sm">
          <label for="pan" class="block mb-1 text-gray-900 dark:text-white" >Pan(0-360)°</label>
          <input id="pan"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            min="0"
            max="360"
            [(ngModel)]="cameraParameters.pan"
            (ngModelChange)="onCameraChange($event, 'pan')"
          />
        </section>
        <section class="text-input text-sm">
          <label for="tilt" class="block mb-1 text-gray-900 dark:text-white" >Tilt(0-90)°</label>
          <input id="tilt"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            min="0"
            max="360"
            [(ngModel)]="cameraParameters.tilt"
            (ngModelChange)="onCameraChange($event, 'tilt')"
          />
        </section>
        <section class="text-input text-sm">
          <label for="zoom" class="block mb-1 text-gray-900 dark:text-white" >Zoom(0-5)</label>
          <input id="zoom"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            min="0"
            max="5"
            [(ngModel)]="cameraParameters.zoom"
            (ngModelChange)="onCameraChange($event, 'zoom')"
          />
        </section>
        <section class="text-input text-sm">
          <label for="height" class="block mb-1 text-gray-900 dark:text-white" >Height(0-100)</label>
          <input id="height"
            class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-8 px-5 w-full"
            type="number"
            min="0"
            max="100"
            [(ngModel)]="cameraParameters.height"
            (ngModelChange)="onCameraChange($event, 'height')"
          />
        </section>
      </div>
    </div>

  </div>
</ng-template>