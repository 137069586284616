<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Change message</h3>
  <p class="text-neutral-100 mt-3 pb-6">Current message: {{getRobotDisplayMessage()}}</p>
    <ng-container>
      <mat-label class="block mb-3">Message</mat-label>
      <div
        class="w-full scrollable"
        itemSize="40"
        maxBufferPx="200"
        minBufferPx="200"
      >
      <shared-form-textarea
        [placeholder]="'Type a message here'"
        [control]="form.get('message')"
        (valueChange)="onTextMessage($event)"
      ></shared-form-textarea>
    </div>
  </ng-container>
</div>
