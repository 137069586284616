import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { SnackBarService } from 'app/services/snack-bar.service';
import { PatrolRouteService } from '../patrol/route-name-media/patrol-route.service';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subscription } from 'rxjs';
import { NewJobService } from 'app/services/new-job.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-display',
  templateUrl: './change-display.component.html',
  styleUrls: ['./change-display.component.scss']
})
export class ChangeDisplayComponent implements OnInit, OnDestroy {

  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() layoutId?: string;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  selectedMediaFile = null;
  mediaList = [];
  private eventsSubscription: Subscription;
  modeOptions = [{display: 'Add', value: 1}, {display: 'Change', value: 2}, {display: 'Delete', value: 3}];
  selectedMode = 1;
  public modeOptionForm: FormControl;

  constructor(
    private _datePipe: DatePipe,
    private dashboardSrvc: DashboardService,
    private snackBar: SnackBarService,
    private newJob: NewJobService,
    private patrolRouteSvc: PatrolRouteService,
  ) { }

  ngOnInit(): void {
    this.getMediaFiles();
    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });

    this.modeOptionForm = new FormControl(
      this.selectedMode || 1,
      Validators.required
    );
  }

  handleChangeMode($event) {
    this.selectedMode = $event.value;
    this.modeOptionForm.setValue($event.value);
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  private getMediaFiles(): void {
    this.patrolRouteSvc.getMediaFiles(1, 10).subscribe((res: any) => {
      if (res.code === 200) {
        this.mediaList = res.result.list || [];
        if (this.selectedMediaFile && !this.mediaList.find(item=>item.id === this.selectedMediaFile.id)) {
          this.selectedMediaFile = null;
        }
      }
    });
  }

  removeMediaFile(id: string, index: number) {
    if (id) {
      this.patrolRouteSvc.deleteMediaFile(id).subscribe((res: any) => {
        if (res.code === 200) {
          this.snackBar.openSnackBar({
            message: 'Delete file success',
            type: 'success',
          });
          if (this.selectedMediaFile && this.selectedMediaFile.id === id) {
            this.selectedMediaFile = null;
          }
          if (index > -1) {
            this.mediaList.splice(index, 1);
          }
        } else {
          this.snackBar.openSnackBar({
            message: 'Delete file failed',
            type: 'failed',
          });
        }
      });
    }
  }

  uploadMediaFile(files: File[]) {
    if(!this.selectedMediaFile && this.selectedMode === 2) {
      this.snackBar.openSnackBar({
        message: 'Please select a file',
        type: 'failed',
      });
      return;
    }
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileNameSuffix = fileName.split('.').pop().toLowerCase();
      const fileSize = file.size;
      let fileType = "";
      if (fileNameSuffix === 'jpg' || fileNameSuffix === 'jpeg' || fileNameSuffix === 'png') {
        fileType = "img";
      } else if (fileNameSuffix === 'zip') {
        fileType = "zip";
      } else if (fileNameSuffix === 'mp4') {
        fileType = "video";
      } else {
        this.snackBar.openSnackBar({
          message: 'The file format is not supported',
          type: 'failed',
        });
        return;
      }
      if(fileSize > 10*1024*1024){
        this.snackBar.openSnackBar({
          message: 'The file size cannot exceed 10M',
          type: 'failed',
        });
        return;
      }
      if(this.selectedMode === 1) {
        this.patrolRouteSvc.uploadMediaFile(file, fileType).subscribe({
          next: (res: any) => {
            if (res && res.code === 201 && res.body.result) {
              if (!this.mediaList.find(item => item.id === res.body.result)) {
                this.snackBar.openSnackBar({
                  message: 'Upload file success',
                  type: 'success',
                });
                this.mediaList.push({
                  id: res.body.result,
                  type: fileType,
                  name: file.name,
                  description: file.type,
                  fileName: file.name
                });
              }
            } else if (res.code !== 201 && res.body.result) {
              if(res.body.result){
                this.patrolRouteSvc.deleteMediaFile(res.body.result).subscribe((res: any) => {
                  console.log('create asset success, but upload file failed');
                });
              }
              this.snackBar.openSnackBar({
                message: 'Upload file failed',
                type: 'failed',
              });
            } else {
              this.snackBar.openSnackBar({
                message: 'Upload file failed',
                type: 'failed',
              });
            }
          },
          error: (error) => {
            this.snackBar.openSnackBar({
              message: 'Upload file failed',
              type: 'failed',
            });
          }
        });
      }
      if(this.selectedMode === 2) {
        this.patrolRouteSvc.updateMediaFile(file, fileType, this.selectedMediaFile.id).subscribe((res) => {
          if (res && res.code === 201 && res.body.result &&!this.mediaList.find(item => item.id === res.body.result)) {
            this.snackBar.openSnackBar({
              message: 'Update file success',
              type: 'success',
            });
            const findIndex = this.mediaList.findIndex(item => item.id === this.selectedMediaFile.id);
            this.mediaList[findIndex].type =  fileType;
            this.mediaList[findIndex].name = file.name;
            this.mediaList[findIndex].description = file.type;
            this.mediaList[findIndex].fileName = file.name;
          } else {
            this.snackBar.openSnackBar({
              message: 'Update file failed',
              type: 'failed',
            });
          }
        }, err => {
          this.snackBar.openSnackBar({
            message: 'Update file failed',
            type: 'failed',
          });
        });
      }
      files = [];
    }
    
  }

  public submitForm(): void {
    if (!this.selectedMediaFile) {
      this.snackBar.openSnackBar({
        message: 'Please select image/video/text ',
        type: 'failed',
      });
      return;
    }
    const params = [];
    params.push(
      {
        paramKey: 'assetId',
        paramValue: this.selectedMediaFile.id,
      }
    );

    const changeDisplaySkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-CHANGEDISPLAY'
    );
    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);
    const payload = {
      followPath: 2,
      mode: 5,
      name:
        'CHANGE DISPLAY ' + this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [
        {
          order: 0,
          skillId: changeDisplaySkill.id,
          params: params,
        },
      ],
      robotIds: [...robotIds],
      scheduledAt: null,
    };

    const successMessage = 'Change display has been sent down successfully'
    const pausedAllRobotsJob: boolean[] = [];
    robotIds.map(async (robotId) => {
      const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
      pausedAllRobotsJob.push(isPaused);
    });
    if (pausedAllRobotsJob.every((result) => result)) {
      payload.mode = 1;
      payload['status'] = 4;
      this.createChangeDisplayJob(payload, successMessage);
    } else {
      console.log(
        'allPaused',
        pausedAllRobotsJob.every((result) => result)
      );
    }
  }

  private createChangeDisplayJob(payload, message) {
    this.newJob.isSubmited$.next(true);
    this.newJob.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: message,
          type: 'success',
        });
        this.closeDrawer.emit();
      } else {
        this.snackBar.openSnackBar({
          message: res.error ? res.error : `Failed to change display.`,
          type: 'failed',
        });
      }
      this.newJob.isSubmited$.next(false);
      this.newJob.isNewJobCreated$.next(true);
    });
  }

}
