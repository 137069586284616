import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-volumecontrol',
  templateUrl: './volumecontrol.component.html',
  styleUrls: ['./volumecontrol.component.scss']
})
export class VolumecontrolComponent implements OnInit {

  
  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() robotSkills: Skill[];
  @Output() resetType = new EventEmitter();
  @Output() closeDrawer = new EventEmitter();
  modeOptions = [{display: 'Dynamic', value: '0'}, {display: 'Manual', value: '1'}];
  selectedMode = '0';
  public modeOptionForm: FormControl;

  skillOptions = [{display: 'Normal', value: '0'}, {display: 'Loud', value: '1'}];
  selectedSkill = '0'
  public skillOptionForm: FormControl;
  private eventsSubscription: Subscription;
  volumeSkills: Skill;

  constructor(
    private snackbarService: SnackBarService,
    private newJob: NewJobService,
    private dashboardSrvc: DashboardService,
    private _datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.selectedMode = '0';
    this.modeOptionForm = new FormControl(
      this.selectedMode || '',
      Validators.required
    );
    this.selectedSkill = '0';
    this.skillOptionForm = new FormControl(
      this.selectedSkill || '',
      Validators.required
    );

    if (this.robotSkills) {
      this.volumeSkills = this.robotSkills.find(
        (skill) => skill.name === 'VOLUMECONTROL'
      );
    }

    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  handleChangeMode($event) {
    this.selectedMode = $event.value;
    this.modeOptionForm.setValue($event.value);
  }

  handleChangeSkill($event) {
    this.selectedSkill = $event.value;
    this.skillOptionForm.setValue($event.value);

  }


  public submitForm(): void {
    const params = [];
    params.push(
      {
        paramKey: 'mode',
        paramValue: +this.selectedMode,
      }
    );
    if(this.selectedMode==='1') {
      params.push(
        {
          paramKey: 'switch',
          paramValue: +this.selectedSkill,
        },
      );
    }


    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);
    const payload = {
      followPath: 2, // 1 = yes; 2 = no;
      mode: 5,
      name:
        'VOLUMECONTROL ' + this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [
        {
          order: 0,
          skillId: this.volumeSkills.id,
          params: params,
        },
      ],
      robotIds: [...robotIds],
      scheduledAt: null,
    };

    // const robotsLength = this.robots.length;
    // const robotsLengthStr = robotsLength > 1 ? 'robots' : 'robot';
    // const blinkingMessage = `Blinking light turned ${this.isBlinkingLightsCheck? 'on' : 'off'}`;  Speaker control set to [Dynamic/Manual] and [Normal/Loud].”
    const successMessage = `Speaker control set to ${this.selectedMode ===  '0'? 'Dynamic' : 'Manual'}${this.selectedMode ===  '1'? this.selectedSkill === '0'? ' and Normal': ' and Loud' : ''}`;

    const pausedAllRobotsJob: boolean[] = [];
    robotIds.map(async (robotId) => {
      const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
      pausedAllRobotsJob.push(isPaused);
    });
    if (pausedAllRobotsJob.every((result) => result)) {
      payload.mode = 1;
      payload['status'] = 4;
      this.createBlinkingLightJob(payload, successMessage);
    } else {
      console.log(
        'allPaused',
        pausedAllRobotsJob.every((result) => result)
      );
    }
  }

  private createBlinkingLightJob(payload, message) {
    //assign isSubmited = true to show loading in button
    this.newJob.isSubmited$.next(true);

    this.newJob.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackbarService.openSnackBar({
          message: message,
          type: 'success',
        });
        this.closeDrawer.emit();
      } else {
        this.snackbarService.openSnackBar({
          message: res.error ? res.error : `Failed to speaker control`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJob.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJob.isNewJobCreated$.next(true);
    });
  }

}
