import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-flood-light',
  templateUrl: './flood-light.component.html',
  styleUrls: ['./flood-light.component.scss']
})
export class FloodLightComponent implements OnInit, OnDestroy {

  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() robotSkills: Skill[];
  private eventsSubscription: Subscription;
  @Output() closeDrawer = new EventEmitter();
  isFloodLightsCheck: boolean;
  selectedMode = 1;
  public modeOptionForm: FormControl;

  constructor(
    private snackbarService: SnackBarService,
    private newJob: NewJobService,
    private dashboardSrvc: DashboardService,
    private _datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.isFloodLightsCheck = false;
    this.selectedMode = 1;
    this.modeOptionForm = new FormControl(
      this.selectedMode || '',
      Validators.required
    );
    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  handleFloodLightsCheck(event) {
    this.isFloodLightsCheck = event.checked;
    if(this.isFloodLightsCheck) {
      this.selectedMode = 1;
    }
  }

  public submitForm(): void {
    const params = [];
    params.push(
      {
        paramKey: 'switch',
        paramValue: this.isFloodLightsCheck? 1: 0,
      }
    );
    let lightSkills = null;
    if (this.robotSkills) {
      lightSkills = this.robotSkills.find(
        (skill) => skill.name === 'RM-LIGHT'
      );
    }

    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);
    const payload = {
      followPath: 2,
      mode: 5,
      name:
        'Head LIGHT ' + (this.isFloodLightsCheck? 'ON ' : 'OFF ') +
        this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [
        {
          order: 0,
          skillId: lightSkills?.id,
          params: params,
        },
      ],
      robotIds: [...robotIds],
      scheduledAt: null,
    };

    const robotsLength = this.robots.length;
    const robotsLengthStr = robotsLength > 1 ? 'robots' : 'robot';
    const floodMessage = `Head light turned ${this.isFloodLightsCheck? 'on' : 'off'}`;
    const successMessage =
      robotsLength > 0
        ? `New job added to ${robotsLength} ${robotsLengthStr} successfully`
        : floodMessage;
    const pausedAllRobotsJob: boolean[] = [];
    robotIds.map(async (robotId) => {
      const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
      pausedAllRobotsJob.push(isPaused);
    });
    if (pausedAllRobotsJob.every((result) => result)) {
      payload.mode = 1;
      payload['status'] = 4;
      this.createLightJob(payload, successMessage);
    } else {
      console.log(
        'allPaused',
        pausedAllRobotsJob.every((result) => result)
      );
    }
  }

  private createLightJob(payload, message) {
    this.newJob.isSubmited$.next(true);
    this.newJob.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackbarService.openSnackBar({
          message: message,
          type: 'success',
        });
        this.closeDrawer.emit();
      } else {
        this.snackbarService.openSnackBar({
          message: res.error ? res.error : `Failed to turn ${this.isFloodLightsCheck? 'on' : 'off'} head light`,
          type: 'failed',
        });
      }
      this.newJob.isSubmited$.next(false);
      this.newJob.isNewJobCreated$.next(true);
    });
  }

}
