import { Skill } from 'rm-api-services/dist/api-services';

export interface AvailableJob {
  name: string;
  skillName: string;
  skill?: Partial<Skill>;
  icon: string;
  jobType: string;
}

export const AVAILABLE_JOBS: AvailableJob[] = [
  {
    name: 'Go to a destination',
    skillName: 'RM-GOTO',
    icon: 'subdirectory_arrow_right',
    jobType: 'goto',
  },
  {
    name: 'Patrol areas',
    skillName: 'RM-GOTO',
    icon: 'pin_drop',
    jobType: 'patrol',
  },
  {
    name: 'Broadcast messages',
    skillName: 'RM-BROADCAST',
    icon: 'chat',
    jobType: 'broadcast',
  },
  {
    name: 'Blinking lights',
    skillName: 'RM-BLINKER',
    icon: 'wb_twilight',
    jobType: 'blinker',
  },
  {
    name: 'Head light',
    skillName: 'RM-LIGHT',
    icon: 'lightbulb',
    jobType: 'floodlight',
  },
  {
    name: 'Return to charge',
    skillName: 'RM-GO-CHARGE',
    icon: 'charging_station',
    jobType: 'charge',
  },
  {
    name: 'Teleconference',
    skillName: 'RM-TELECONFERENCE',
    icon: 'video_call',
    jobType: 'calling',
  },
  {
    name: 'Speaker Control',
    skillName: 'VOLUMECONTROL',
    icon: 'volume_mute',
    jobType: 'volume',
  },

  {
    name: 'Change display',
    skillName: 'RM-CHANGEDISPLAY',
    icon: 'track_changes',
    jobType: 'changeDisplay',
  },
  {
    name: 'Change message',
    skillName: 'RM-CHANGEMESSAGE',
    icon: 'message',
    jobType: 'changeMsg',
  },
];

export const AVAILABLE_MULTIPLE_ROBOT_JOBS: AvailableJob[] = [
  {
    name: 'Broadcast messages',
    skillName: 'RM-BROADCAST',
    icon: 'chat',
    jobType: 'broadcast',
  },
  {
    name: 'Blinking lights',
    skillName: 'RM-BLINKER',
    icon: 'wb_twilight',
    jobType: 'blinker',
  },
  {
    name: 'Group patrol',
    skillName: 'RM-GOTO',
    icon: 'pin_drop',
    jobType: 'group-patrol',
  },
];
