<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Change display</h3>
  <!-- <p class="text-neutral-100 mt-3">Change display.</p> -->

  <!-- <div class="flex shrink-0 items-center justify-between pt-4 pb-4 text-on-primary">
    <div class="flex items-center justify-between w-full">
      <div class="font-semibold">Display</div>
      <button class="text-sm font-semibold text-primary disabled:text-neutral-200" [disabled]="!selectedMediaFile"
        (click)="selectedMediaFile = null">
        Clear Check
      </button>
    </div>
  </div> -->

  <div class="mt-5 max-w-[200px]">
    <shared-form-select
      [label]="'Mode'"
      [data]="modeOptions"
      [showLabel]="true"
      [selectedOption]="selectedMode"
      [controlName]="modeOptionForm"
      (selectionChange)="handleChangeMode($event)"
    ></shared-form-select>
  </div>

  <div class="pt-4 pb-4 pr-8">
    <ng-container *ngIf="mediaList.length > 0">
      <mat-radio-group [(ngModel)]="selectedMediaFile">
        <div *ngFor="let mediaFile of mediaList; let i=index" class="flex justify-between items-center">
          <mat-radio-button class="flex justify-between items-center mb-4 media-radio-button " [value]="mediaFile"
            [checked]="selectedMediaFile && mediaFile.id === selectedMediaFile.id">
            <div class="flex justify-between items-center">
              <mat-icon *ngIf="mediaFile.type === 'image/png' || mediaFile.type === 'image/jpeg'"
                svgIcon="feather:media-img"></mat-icon>
              <mat-icon *ngIf="mediaFile.type === 'video/quicktime'" svgIcon="feather:media-video"></mat-icon>
              <span class="w-60 pl-3 truncate">{{mediaFile.fileName}}</span>
            </div>
          </mat-radio-button>
          <button class="self-center w-5 h-5 mb-3 ml-2" (click)="removeMediaFile(mediaFile.id, i)" *ngIf="selectedMode===3"
            title="Remove media file">
            <mat-icon svgIcon="feather:x-circle" class="text-neutral-200 icon-size-4"></mat-icon>
          </button>
        </div>
      </mat-radio-group>
    </ng-container>
  </div>

  <div class="rounded border-collapse w-32 border border-primary p-2" *ngIf="selectedMode===1 || selectedMode===2">
    <input id="avatar-file-input" class="absolute h-0 w-0 opacity-0 invisible pointer-events-none" type="file"
      [multiple]="false" accept=".jpg,.jpeg,.png,.mp4,.zip"
      (change)="uploadMediaFile(mediaFileInput.files)" #mediaFileInput>
    <label class="flex items-center justify-center cursor-pointer text-primary" for="avatar-file-input" matRipple>
      <mat-icon class="text-primary disabled:text-stone-500" [svgIcon]="'heroicons_solid:upload'"></mat-icon>
      <span class="w-full" *ngIf="selectedMode===1">Upload File</span>
      <span class="w-full" *ngIf="selectedMode===2">Change File</span>
    </label>
  </div>
  <p class="text-neutral-100 mt-3" *ngIf="selectedMode===1 || selectedMode===2">(Accepted Files: Image, mp4, zip)</p>
</div>