import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ToastData } from 'app/modules/dashboard/layout-map/layout-map.service';

@Component({
  selector: 'shared-toast-container',
  template: `<div
    class="toast-container flex flex-col gap-2"
    [ngClass]="{
      'top-36': isMalfunction,
      'top-5': !isMalfunction && !isInGroupPatrol,
      'top-20': isInGroupPatrol,
      'max-h-[calc(100vh-70px)]': isInGroupPatrol,
      'max-h-[calc(100vh-500px)]': !isInGroupPatrol,
      fixed: isInGroupPatrol,
      absolute: !isInGroupPatrol
    }"
  >
    <div *ngFor="let toast of toastData; index as i">
      <shared-job-failed-toast
        [data]="toast"
        *ngIf="toast.type === 'job-failed'"
        (click)="hide(toast.id)"
      ></shared-job-failed-toast>
      <app-low-battery-toast
        [data]="toast"
        [isInGroupPatrol]="isInGroupPatrol"
        *ngIf="toast.type === 'low-battery'"
        (rellocateRobot)="onRellocateRobot($event, 'low-battery')"
      ></app-low-battery-toast>
      <shared-robot-arrived-toast
        [data]="toast"
        *ngIf="toast.type === 'robot-arrived'"
        (dismissToast)="hide(toast.id)"
      ></shared-robot-arrived-toast>
      <malfunction-card-toast
        *ngIf="toast.type === 'robot-malfunction'"
        [robots]="[toast.data]"
        [totalRobot]="1"
        [isInGroupPatrol]="isInGroupPatrol"
        (rellocateRobot)="onRellocateRobot($event, 'robot-malfunction')"
      ></malfunction-card-toast>
      <app-emergency-stop-toast
        [data]="toast"
        *ngIf="toast.type === 'robot-estop'"
        (dismissToast)="hide(toast.id)"
      >
      </app-emergency-stop-toast>
    </div>
  </div>`,
  styles: [
    `
      .toast-container {
        right: 20px;
        scroll-behavior: smooth;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 99;
      }
    `,
  ],
})
export class ToastContainerComponent implements OnInit, OnDestroy {
  public toastData: ToastData[] = [];
  @Input() isMalfunction: boolean = false;
  @Input() isInGroupPatrol: boolean = false;
  @Output() rellocateRobot = new EventEmitter<{
    robotId: string;
    type: string;
  }>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.toastData = [];
  }

  hide(id: string): void {
    // remove toast from queue
    const newData = this.toastData.filter((item) => item.id !== id);
    this.toastData = newData;
  }

  public onRellocateRobot(robotId: string, type: string): void {
    this.rellocateRobot.emit({ robotId, type });
  }
}
