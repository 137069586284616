import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emergency-stop-toast',
  templateUrl: './emergency-stop-toast.component.html',
  styleUrls: ['./emergency-stop-toast.component.scss']
})
export class EmergencyStopToastComponent implements OnInit {

  @Output() dismissToast = new EventEmitter();

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.dismissToast.emit();
    }, 5*1000)
  }

}
