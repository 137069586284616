import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { MapLibreService } from 'app/modules/dashboard/gis-map/services/map-libre.service';
import { JobListService } from 'app/modules/dashboard/joblist.service';
import { LayoutMapService } from 'app/modules/dashboard/layout-map/layout-map.service';
import { Campaign, DispatchRobotPayload, RobotSocketData } from 'app/services/api.types';
import { ApiCampaign } from 'app/services/campaign.service';
import { CurrentMissionService } from 'app/services/current-job.service';
import { DispatchRobotService } from 'app/services/dispatch-robot.service';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { UtilityService } from 'app/services/utility.service';
import _ from 'lodash';
import moment from 'moment';
import {
  Mission,
  MqttSettings,
  Robot,
} from 'rm-api-services/dist/api-services';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'dispatch-robot-creation',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss'],
})
export class DispatchComponent implements OnInit, OnDestroy {
  @Input() events: Observable<void>;
  @Input() skillId: string;
  @Input() robots: Robot[];
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();
  @Output() changeRobotSelection = new EventEmitter<string>();

  private checkOfflineTimer;
  private robotId: string;
  private onLineRobotIdList: string[] = [];
  private dispatchRobotPayload: DispatchRobotPayload;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private currentJob: Mission;
  private robotStateMap: Map<string, number> = new Map<string, number>();

  private activeMissionList: Mission[] = [];
  public robotOnlineList: Robot[] = [];
  public robotActivePatrolJob: Campaign;
  activeMissionsSub: Subscription;
  evenrsSub: Subscription;
  robotSocketSub: Subscription;
  robotRbtStateSub: Subscription;


  constructor(
    private mqttSettings: MqttSettings,
    private dashboardService: DashboardService,
    private snackBar: SnackBarService,
    private datePipe: DatePipe,
    private apiDispatchRobot: DispatchRobotService,
    private newJobSrvc: NewJobService,
    private confirmationDialogService: FuseConfirmationService,
    private currentMissionSrvc: CurrentMissionService,
    private leafletService: LayoutMapService,
    private maplibreService: MapLibreService,
    private _dashboardService: DashboardService,
    private joblistService: JobListService,
    private utilityService: UtilityService,
    private apiCampaign: ApiCampaign,
  ) {}

  ngOnInit(): void {
    this.activeMissionsSub = this.joblistService.activeMissions$.subscribe((data) => {
      this.activeMissionList = data.map((job) => ({
        ...job,
        skills: this.utilityService.loadingSkillNames(job),
      }));
    })
    this.evenrsSub = this.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      if (this.currentJob) {
        this.openConfirmationDialog();
      } else {
        this.submitForm();
      }
    });

    // Subscribe the dispatch robot payload
    this.dashboardService.dispatchRobotPayload$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((payload) => {
        this.dispatchRobotPayload = payload;
      });

    // Subscribe to MQTT robot data topic
    this.robotSocketSub = this.mqttSettings.socketRobotData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: RobotSocketData) => {
        if (data && data.layout && data.robotId) {
          const robotIndex = this.robots.findIndex(
            (robot) => robot.id === data.robotId
          );

          // Update the robot marker position based on MQTT message re-render the marker in the map
          if (robotIndex > -1) {
            // Check if the robot state is not 4 or charging
            // Only add the robot to the online list if the robot state is not 4 or charging
            const robotState = this.robotStateMap.get(data.robotId) || 0;
            if (robotState !== 4) {
              if (!this.robotOnlineList.includes(this.robots[robotIndex])) {
                // Add robot to robotOnlineList
                this.robotOnlineList.push(this.robots[robotIndex]);

                // Sort robotOnlineList based on the order of robots in the original list
                this.robotOnlineList.sort((a, b) => {
                  return this.robots.indexOf(a) - this.robots.indexOf(b);
                });
              }

              if (!this.onLineRobotIdList.includes(data.robotId)) {
                this.onLineRobotIdList.push(data.robotId);
              }
            }
          }

          // Update the robot status
          this.updateRobotStatusBySocket(data);
        }
      });

    // Subscribe to MQTT robot state topic
    this.robotRbtStateSub = this.mqttSettings.socketRbtStateData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data && data.robotId) {
          // Update the robot state in the map
          this.robotStateMap.set(data.robotId, data.state);

          // Check if the state is 4 and the robot is in the online list
          // then remove the robot from the online list if the state is 4
          if (
            data.state === 4 &&
            this.onLineRobotIdList.includes(data.robotId)
          ) {
            // Find the index of the robot in robotOnlineList
            const indexToRemove = this.robotOnlineList.findIndex(
              (onlineRobot) => onlineRobot.id === data.robotId
            );

            // Remove the robot from robotOnlineList
            if (indexToRemove !== -1) {
              this.robotOnlineList.splice(indexToRemove, 1);
            }
          }
          this.updateRobotStateBySocket(data);
        }
      });

    this.checkRobotOfflineTimer();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.activeMissionsSub?.unsubscribe();
    this.evenrsSub?.unsubscribe();
    this.robotSocketSub?.unsubscribe();
    this.robotRbtStateSub?.unsubscribe();
  }

  public selectRobot(robotId: string): void {
    this.robotId = robotId;
    this.changeRobotSelection.emit(robotId);
    this.getCurrentMission();

    const robot = this.robotOnlineList.find((robot) => robot.id === robotId);
    if (robot) {
      this.showRobotOnMap(robot);
      this.dashboardService.selectedDispatchRobotId$.next(robotId);
    }
  }

  private openConfirmationDialog(): void {
    const dialogRef = this.confirmationDialogService.open({
      title: 'Confirm dispatch robot',
      message: `<span class="text-neutral-100">This robot has an ongoing task, proceed with dispatch?</br>Remaining task will be paused.<span>`,
      icon: {
        show: false,
      },
      actions: {
        confirm: {
          show: true,
          label: 'Dispatch',
          color: 'primary',
        },
        cancel: {
          show: true,
          label: 'Cancel',
        },
      },
      dismissible: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.submitForm();
      }
    });
  }

  /**
   * Helper function to create goto job
   */
  private submitForm(): void {
    if (!this.dispatchRobotPayload) {
      return;
    }

    if (!this.robotId) {
      this.snackBar.openSnackBar({
        message: 'Please select a robot to dispatch',
        type: 'failed',
      });
      return;
    }

    const parsedLocation = JSON.parse(this.dispatchRobotPayload.location);
    const officerId = localStorage.getItem('user_id');
    const robot = this.robots.find((robot) => robot.id === this.robotId);

    const dispatchData = {
      officerId,
      reporterName: this.dispatchRobotPayload.reporterName,
      phoneNumber: this.dispatchRobotPayload.phoneNumber,
      reportBy: this.dispatchRobotPayload.reportBy,
      layoutId: this.dispatchRobotPayload.layoutId,
      location: {
        x: parsedLocation.x,
        y: parsedLocation.y,
        z: parsedLocation.z,
      },
      robotId: this.robotId,
      dispatchNote: this.dispatchRobotPayload.dispatchNote,
      missionDto: {
        name:
          'DISPATCH GOTO ' +
          this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        description: parsedLocation.name ? parsedLocation.name : '',
        priority: 1,
        mode: 1,
        type: 1,
        layoutId: this.dispatchRobotPayload.layoutId,
        tasks: [
          {
            order: 0,
            skillId: this.skillId,
            params: [
              {
                paramKey: parsedLocation.name ? parsedLocation.name : 'POINT1',
                paramValue: this.dispatchRobotPayload.location,
              },
            ],
            layoutId: this.dispatchRobotPayload.layoutId,
            followPath: 1,
          },
        ],
        robotIds: [this.robotId],
        repeat: 0,
        followPath: 1,
      },
    };

    this.apiDispatchRobot.createDispatch(dispatchData).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: `Dispatch ${robot.name} to dispatch location successfully.`,
          type: 'success',
        });
        //After dispatch, need to rebalance auto
        const dispatchRobotId = this.robotId;
        setTimeout(() => {
          this.getPatrolJob(dispatchRobotId);
        }, 2*1000)
        this.robotId = undefined;
        this.closeDrawer.emit();
        this.resetType.emit();
        this.dashboardService.selectedDispatchRobotId$.next(undefined);
      } else {
        this.snackBar.openSnackBar({
          message: res.error ? res.error : `Failed dispatch ${robot.name} to dispatch location`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    }, err => {
      this.snackBar.openSnackBar({
        message: err.error?.error ? err.error.error : `Failed dispatch ${robot.name} to dispatch location`,
        type: 'failed',
      });
      this.newJobSrvc.isSubmited$.next(false);
    });
  }

  getPatrolJob(dispatchRobotId: string) {
    const robotCampaign = this.activeMissionList?.findIndex((mission) => mission['robots'].find(robot => robot['id'] === dispatchRobotId) && mission['campaignId'] !== '00000000-0000-0000-0000-000000000000');
    if(robotCampaign === -1){
      return;
    }
    const patrolJobId = this.activeMissionList[robotCampaign]['campaignId'];
    if(!patrolJobId){
      return;
    }
    let updatedPatrolJob: Partial<Campaign>;
    this.apiCampaign.getById(patrolJobId).subscribe((data) => {
      if (data.code === 200 && data.result) {
        updatedPatrolJob = data.result;
        if(updatedPatrolJob['robotList'].length > 1) {
          if (updatedPatrolJob?.layoutId) {
            this.utilityService
              .getLocationName(updatedPatrolJob.layoutId)
              .subscribe((res) => {
                updatedPatrolJob.layoutName = res;
                // remove robotId from updatedPatrolJob.robots
                updatedPatrolJob.robotList = updatedPatrolJob.robotList.filter((robot) => robot !== dispatchRobotId);
                const selectedRobot = this.robots.find((robot) => robot.id === dispatchRobotId);
                this.updatePatrolJob(patrolJobId, updatedPatrolJob,  `Success reallocate ${selectedRobot.name}'s task to other robot.`,);
              });
          }
        }
      }
    });
  }
  private updatePatrolJob(
    patrolJobId: string,
    updatedPatrolJob: Partial<Campaign>,
    successMessage: string,
  ): void {
    this.apiCampaign
      .updateCampaign(patrolJobId, updatedPatrolJob)
      .subscribe(async (response) => {
        if (response.code === 200 && response.result) {
          this.snackBar.openSnackBar({
            message: successMessage,
            type: 'success',
          });
        }
      });
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStatusBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      if (robot.id === socketData.robotId) {
        robot.status = 1;
        robot.battery = Number(socketData.battery);
        robot.connectivity = socketData.connectivity;
        // check the length of the timestamp, because the moment library
        // has two functions to convert timestamp into date format
        // and some of the robot, send status using this two formats.
        // the length can be 10 or 13
        if (socketData.timestamp.toString().length > 10) {
          robot.lastOnlineTime = moment(socketData.timestamp).fromNow();
        } else {
          robot.lastOnlineTime = moment.unix(socketData.timestamp).fromNow();
        }
        robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    });
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStateBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      // then update the state
      if (robot.id === socketData.robotId) {
        robot.state = socketData.state;
      }
    });
  }

  /**
   * Helper function to check the robot status from MQTT for every 3 seconds.
   * It is used in robot list in the layout marker popup
   *
   */
  private checkRobotOfflineTimer(): void {
    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this.checkOfflineTimer = setInterval(() => {
      if (this.onLineRobotIdList.length > 0) {
        this.robots.map((robot) => {
          if (this.onLineRobotIdList.includes(robot.id)) {
            const currentTime = moment();
            const updatedTime = moment(robot.updatedAt);
            const differenceInSeconds = currentTime.diff(
              updatedTime,
              'seconds'
            );
            if (robot.updatedAt && differenceInSeconds > 10) {
              robot.status = 2;
              const temp = this.onLineRobotIdList.filter(
                (id) => id !== robot.id
              );
              robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
              this.onLineRobotIdList = _.cloneDeep(temp);

              // Find the index of the robot in robotOnlineList
              const indexToRemove = this.robotOnlineList.findIndex(
                (onlineRobot) => onlineRobot.id === robot.id
              );

              // Remove the robot from robotOnlineList
              if (indexToRemove !== -1) {
                this.robotOnlineList.splice(indexToRemove, 1);
              }

              // remove the offline robot from selectRobot
              if (robot.id === this.robotId) {
                this._dashboardService.selectedRobotId$.next(undefined);
                this.robotId = undefined;
              }
            }
          }
        });
      }
    }, 3 * 1000);
  }

  /**
   * Get the current job and check if the current job is conflict with the new job
   */
  private getCurrentMission(): void {
    this.currentJob = undefined;
    const robot = this.robots.find((robot) => robot.id === this.robotId);
    if (!robot) {
      return;
    }

    this.currentMissionSrvc
      .getSubject(robot)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((missions) => {
        // check if there is a mission list
        if (missions.length > 0) {
          this.currentJob = missions?.find((mission) => mission.status === 5);
        }
      });
  }

  /**
   * Helper function to show robot location on the map, when user click the location
   * button in the drawer. If the selected robot is on different layout with current
   * layout map in same building, it will render the layout map first then move
   * the map center to selected robot location
   *
   * @param robot
   */
  private showRobotOnMap(robot: Robot): void {
    if (robot.point && this.leafletService.isMapInitiate()) {
      const robotPointX = robot.point.x + 500;
      const coordinates: L.PointExpression = [robotPointX, robot.point.y];
      this.dashboardService.selectedRobotCoordinate$.next(coordinates);
    }

    if (robot.location && this.maplibreService.isMapInitiate()) {
      const robotLng = robot.location.lng + 0.05;
      const coor: [number, number] = [robotLng, robot.location.lat];
      this.maplibreService.flyTo({
        center: coor,
      });
    }
  }
}
