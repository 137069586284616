<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Blinking light</h3>

  <p class="text-neutral-100 mt-3">Command robot to turn on/off blinking lights.</p>

  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]">
    <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type>
  </div>

  <div class="pb-3">
    <mat-divider class="mt-6 mb-8"></mat-divider>
    <div class="w-full">
      <slide-toggle
        class="mt-2"
        [label]="'Blinking lights'"
        [description]="'Flash blinking lights until this job is completed.'"
        (onChange)="handleBlinkingLightsCheck($event)"
      ></slide-toggle>
      <!-- [isChecked]="isBlinkingLightsCheck" -->
    </div>
  </div>

  <div class="mt-5 max-w-[200px] pb-3" *ngIf="isBlinkingLightsCheck">
    <shared-form-select
      [label]="'On mode'"
      [data]="modeOptions"
      [showLabel]="true"
      [selectedOption]="selectedMode"
      [controlName]="modeOptionForm"
      (selectionChange)="handleChangeMarker($event)"
    ></shared-form-select>
  </div>

  <!-- form for duration turn on light -->
  <form [formGroup]="form" class="flex flex-col gap-2 mt-0" *ngIf="isBlinkingLightsCheck">
    <rm-repeat
      [radioButtonLabel]="'On repeat'"
      [inputFieldLabel]="'No. of repeat'"
      [placeholder]="'Enter number of repeat'"
      [inputType]="'number'"
      [min]="1"
      [value]="form.get('repeat')"
      (onRepeatChange)="onRepeatChange($event)"
    ></rm-repeat>
  </form>
</div>
