import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { SelectOption } from '../form-select/form-select.component';

@Component({
  selector: 'rm-select-start-job-type',
  styles: [''],
  template: `
    <div class="flex flex-row gap-2 items-center">
      <span class="translate-y-[-14px]"> Start this job </span>
      <shared-form-select
        class="w-56 max-w-56"
        [label]="'Job order'"
        [hint]="selectedDescription"
        [data]="typeOfStartJobOptions"
        [showLabel]="false"
        [selectedOption]="selectedOption"
        [controlName]="startJobOptionForm"
        (selectionChange)="selectStartJobType($event)"
      ></shared-form-select>
      <span class="translate-y-[-14px]"> the current job </span>
    </div>

    <div *ngIf="selectedOption === 3" class="w-full">
      <form [formGroup]="scheduleForm" class="w-full mt-1">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            placeholder="Choose a date"
            [matDatepicker]="picker"
            formControlName="inputDate"
            (dateInput)="dateChange($event)"
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Choose time</mat-label>
          <input
            matInput
            placeholder="Enter time"
            type="time"
            formControlName="inputTime"
            (change)="dateChange($event)"
          />
        </mat-form-field>
      </form>
    </div>
  `,
})
export class RmSelectStartJobTypeComponent implements OnInit {
  @Input() label: string = null;
  @Output() selectionChange = new EventEmitter<number>();
  @Output() jobSchedule = new EventEmitter<string>();

  public typeOfStartJobOptions: SelectOption[] = [];
  public selectedOption: number;
  public selectedDate: string;
  public selectedDescription: string = ' ';
  public scheduleTime: string = '';
  public scheduleForm: FormGroup;
  public startJobOptionForm: FormControl;

  private typeOfStartJobs: StartJobType[] = [];

  ngOnInit(): void {
    this.initForm();
    this.getStartJobTypesData();
    // set default to "Start now and pause current job"
    this.selectedOption = 4;
    this.startJobOptionForm = new FormControl(
      this.selectedOption || '',
      Validators.required
    );
  }

  dateChange(event: MatDatepickerInputEvent<Date>): void {
    if (this.selectedOption === 3) {
      this.jobSchedule.emit(this.getDate());
    }
  }

  initForm(): void {
    const controls = {
      inputTime: new FormControl('', Validators.required),
      inputDate: new FormControl('', Validators.required),
    };
    this.scheduleForm = new FormGroup(controls);
  }

  selectStartJobType(selection: SelectOption): void {
    this.selectedOption = selection.value;
    this.startJobOptionForm.setValue(selection.value);
    const selectedType = this.typeOfStartJobs.find(
      (type) => type.value === selection.value
    );
    this.selectedDescription = selectedType.description || ' ';

    this.selectionChange.emit(this.selectedOption);
  }

  getDate(): string {
    const inputDate = new Date(
      this.scheduleForm.get('inputDate').value
    ).toLocaleDateString();
    const inputTime = this.scheduleForm.get('inputTime').value;
    let date = new Date(`${inputDate} ${inputTime}`).toISOString();
    return date;
  }

  private getStartJobTypesData(): void {
    fetch('/assets/config/start-job-type.json')
      .then((response) => response.text())
      .then((data) => {
        this.typeOfStartJobs = JSON.parse(data) as StartJobType[];
        this.typeOfStartJobs.map((startJob) => {
          this.typeOfStartJobOptions.push({
            display: startJob.type,
            value: startJob.value,
          });
        });
      })
      .catch((error) => {
        console.error('Error reading file:', error);
      });
  }
}

export interface StartJobType {
  type: string;
  value: number;
  description?: string;
}
