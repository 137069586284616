import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ListQuery,
  ResponseList,
  ApiRobot,
  Robot,
} from 'rm-api-services/dist/api-services';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiRobotService extends ApiRobot {
  updateSumarry$ = new Subject<boolean>();

  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
  }

  // Recursive method to fetch all data with a query payload
  public getAllRobotListByLayout(queryPayload: ListQuery): Promise<Robot[]> {
    const { pageNo, pageSize = 50, ...restQueryParams } = queryPayload;

    // check if pageNo is valid
    if (typeof pageNo !== 'number' || pageNo < 1) {
      return Promise.reject(new Error('Invalid pageNo'));
    }

    return this.getRobotListByLayout({
      pageNo,
      pageSize,
      ...restQueryParams, // Merge any additional query parameters
    })
      .toPromise()
      .then((res: ResponseList<Robot>) => {
        const data = res.result.list;
        const totalRecords = res.result.totalRecords;

        if (data.length === 0 || pageNo * pageSize >= totalRecords) {
          // All data has been fetched or reached the end
          return data;
        } else {
          // Fetch the next page of data
          return this.getAllRobotListByLayout({
            ...queryPayload,
            pageNo: pageNo + 1,
          })
            .then((dataRes) =>
              // Concatenate the current and next page of data
              data.concat(dataRes)
            )
            .catch((err) => Promise.reject(err));
        }
      })
      .catch((err) => Promise.reject(err));
  }
}
