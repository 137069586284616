import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SelectOption } from '../form-select/form-select.component';
import { RadioButton } from '../radio-button/radio-button.component';

@Component({
  selector: 'rm-repeat',
  template: `<div class="flex flex-col gap-5 w-full max-w-90">
    <shared-form-select
      [label]="'On repeat'"
      [hint]="selectedDescription"
      [data]="radioButtons"
      [showLabel]="true"
      [selectedOption]="isRepeatFormControl.value"
      [controlName]="isRepeatFormControl"
      (selectionChange)="changeRepeatStatus($event.value)"
    ></shared-form-select>
    <ng-container *ngIf="isRepeatFormControl.value === 1">
      <text-input
        class="max-w-44"
        rmDigitOnly
        [label]="inputFieldLabel"
        [type]="inputType"
        [placeholder]="placeholder"
        [controlName]="totalRepeatFormControl"
        (valueChange)="changeRepeatNumber($event)"
        [min]="min || 1"
      ></text-input>
    </ng-container>
  </div>`,
})
export class RmRepeatComponent implements OnInit {
  @Input() radioButtonLabel: string;
  @Input() inputFieldLabel: string;
  @Input() placeholder: string;
  @Input() inputType: string;
  @Input() min: number;
  @Input() value: number | string | null;
  @Output() onRepeatChange = new EventEmitter<number>();

  public selectedDescription: string = ' ';

  radioButtons: SelectOption[] = [
    {
      value: 1,
      display: 'Set number of repeats',
    },
    {
      value: 0,
      display: 'Does not repeat',
    },
    {
      value: 2,
      display: 'Indefinitely',
    },
  ];
  isRepeatFormControl: FormControl<number>;
  totalRepeatFormControl: FormControl<number>;

  ngOnInit(): void {
    this.isRepeatFormControl = new FormControl(2);
    this.totalRepeatFormControl = new FormControl(null, [Validators.required]);
  }

  public changeRepeatStatus(value: number): void {
    this.isRepeatFormControl.setValue(value);

    switch (value) {
      case 0:
        this.totalRepeatFormControl.setValue(0);
        break;

      case 1:
        this.totalRepeatFormControl.setValue(1);
        break;

      case 2:
        this.totalRepeatFormControl.setValue(-1);
        break;

      default:
        this.totalRepeatFormControl.setValue(0);
        break;
    }

    this.emitTotalRepeat();
  }

  public changeRepeatNumber(value: number): void {
    const totalRepeat = value === null ? 1 : value;
    this.totalRepeatFormControl.setValue(+totalRepeat);
    this.emitTotalRepeat();
  }

  private emitTotalRepeat() {
    if (this.totalRepeatFormControl.valid) {
      this.onRepeatChange.emit(this.totalRepeatFormControl.value);
    }
  }
}
