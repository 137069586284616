<div class="h-full">
  <!-- If loading -->
  <ng-container *ngIf="isLoading">
    <div class="flex items-center justify-center">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-container>
  <!-- Content -->
  <ng-container class="relative" *ngIf="robot && !isLoading">
    <div class="flex justify-between">
      <div class="flex items-center">
        <h2 class="text-lg">
          {{ robot.name }}
        </h2>
      </div>
      <!-- Robot battery and signal component -->
      <div class="flex items-center justify-between" *ngIf="robot.status !== 2">
        <shared-robot-signal
          [connectivity]="robot.connectivity"
        ></shared-robot-signal>
        <shared-robot-battery
          [percentage]="robot.battery"
        ></shared-robot-battery>
      </div>
    </div>
    <div class="w-full h-15" *ngIf="robot.state === 5"></div>
    <div
      class="absolute left-0 flex w-full top-14"
      [ngClass]="{
        'top-18': robot.status !== 2,
        'top-14': robot.status === 2
      }"
      *ngIf="robot.state === 5 || robot.eventCount > 0"
    >
      <robot-detail-banner
        class="w-full"
        [isMalfunctioning]="robot.state === 5"
        [robotEventCount]="robot.eventCount"
        [selectedRobotData]="robot"
      ></robot-detail-banner>
    </div>
    <div
      class="flex flex-col"
      [ngClass]="robot.eventCount > 0 ? 'mt-18' : 'mt-5'"
      *ngIf="robot.state !== 5"
    >
      <!-- Status -->
      <robot-status [robot]="robot"></robot-status>
      <!-- Warning Low Battery -->
      <div class="mt-2">
        <span
          *ngIf="robot.battery < robot.warningThreshold && robot.battery > 0 && robot.status !== 2"
          class="text-warning"
          >Warning! Battery is running low</span
        >
      </div>
      <!-- Last Online if inactive -->
      <div
        class="flex flex-row items-center gap-2 mt-1 text-neutral-100"
        *ngIf="robot.status == 2"
      >
        <mat-icon
          class="scale-75 justify-start -m-[0.27rem]"
          svgIcon="mat_solid:access_time"
        ></mat-icon>
        <span>Last Online: </span>
        <span *ngIf="robot.lastOnlineTime">
          {{
            robot.lastOnlineTime !== null &&
            robot.lastOnlineTime.includes('ago')
              ? robot.lastOnlineTime
              : (robot.lastOnlineTime | robotLastOnline)
          }}
        </span>
        <span *ngIf="!robot.lastOnlineTime">-</span>
      </div>
    </div>

    <!-- New job and Teleoperate button -->
    <div class="flex justify-between mt-5">
      <div class="flex items-center gap-4">
        <button
          mat-flat-button
          [color]="'primary'"
          class="h-10 transition rounded-md max-h-10 hover:bg-primary-800"
          [matMenuTriggerFor]="jobsList"
        >
          <mat-icon class="mr-1" svgIcon="mat_solid:add"></mat-icon>
          Job
        </button>
        <!-- Mat Menu list of job robot related to skill -->
        <mat-menu #jobsList="matMenu" class="background-jobs">
          <!-- if robot has skill -->
          <ng-container *ngIf="robot.availableJobs?.length > 0; else noSkill">
            <button
              mat-menu-item
              *ngFor="let job of robot.availableJobs"
              (click)="onCreateNewJob(job.skill, robot, job.jobType)"
              class="text-md"
            >
              <mat-icon
                class="mr-5 scale-75"
                svgIcon="mat_outline:{{ job.icon }}"
              ></mat-icon>
              {{ job.name }}
            </button>
          </ng-container>
          <!-- if robot has no skill related to the default job -->
          <ng-template #noSkill>
            <button mat-menu-item disabled>No available jobs</button>
          </ng-template>
        </mat-menu>
        <button
          mat-flat-button
          class="h-10 transition bg-white rounded-md max-h-10 text-neutral hover:text-primary-500"
          (click)="robot.status !== 2 ? gotoTeleops() : null"
          *ngIf="robot.skillArr | checkSkill : teleopSKill"
          [disabled]="robot.status === 2"
        >
          <mat-icon class="mr-1" svgIcon="mat_solid:camera_rear"></mat-icon>
          Live View
        </button>
      </div>
    </div>
  </ng-container>
  <!-- Content for job list -->
  <ng-container>
    <ng-content></ng-content>
  </ng-container>
</div>
