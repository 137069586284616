import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { NewJobService } from 'app/services/new-job.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { MqttSettings, Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-change-message',
  templateUrl: './change-message.component.html',
  styleUrls: ['./change-message.component.scss']
})
export class ChangeMessageComponent implements OnInit {

  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() layoutId?: string;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  form: FormGroup;
  private eventsSubscription: Subscription;
  robotSocketSub: Subscription;

  constructor(
    private _datePipe: DatePipe,
    private dashboardSrvc: DashboardService,
    private snackBar: SnackBarService,
    private newJob: NewJobService,
    private _mqttSettings: MqttSettings,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      message: new FormControl()
    });

    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });

    this.robotSocketSub = this._mqttSettings.socketRobotData$.subscribe((data) => {
      if(data.id === this.robot.id) {
        this.robot = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
    this.robotSocketSub?.unsubscribe();
  }

  onTextMessage(data: string): void {
    this.form.patchValue({
      message: data,
    });
  }


  public submitForm(): void {
    const params = [];
    params.push(
      {
        paramKey: 'text',
        paramValue: this.form.get('message').value,
      }
    );
    const changeMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-CHANGEMESSAGE'
    );
    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);
    const payload = {
      followPath: 2,
      mode: 5,
      name:
        'CHANGE MESSAGE ' + this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [
        {
          order: 0,
          skillId: changeMessageSkill.id,
          params: params,
        },
      ],
      robotIds: [...robotIds],
      scheduledAt: null,
    };
    const successMessage = 'Change message has been sent down successfully'
    const pausedAllRobotsJob: boolean[] = [];
    robotIds.map(async (robotId) => {
      const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
      pausedAllRobotsJob.push(isPaused);
    });

    if (pausedAllRobotsJob.every((result) => result)) {
      payload.mode = 1;
      payload['status'] = 4;
      this.createChangeMsgJob(payload, successMessage);
    } else {
      console.log(
        'allPaused',
        pausedAllRobotsJob.every((result) => result)
      );
    }
  }

  private createChangeMsgJob(payload, message) {
    this.newJob.isSubmited$.next(true);
    this.newJob.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: message,
          type: 'success',
        });
        this.closeDrawer.emit();
      } else {
        this.snackBar.openSnackBar({
          message: res.error ? res.error : `Failed to change message.`,
          type: 'failed',
        });
      }
      this.newJob.isSubmited$.next(false);
      this.newJob.isNewJobCreated$.next(true);
    });
  }

  getRobotDisplayMessage(): string {
    const stateDetail = this.robot['stateDetail'];
    try {
      const stateDetailJson = JSON.parse(stateDetail);
      return stateDetailJson?.status?.displaymessage ? stateDetailJson.status.displaymessage  : '-';
    } catch (e) {
      return '-';
    }
  }
}
