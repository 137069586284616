<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Speaker Control</h3>
  <p class="text-neutral-100 mt-3">Operator may switch robot's speaker mode between Dynamic and Manual mode. On Manual mode, operator may choose between Normal or Loud output volume.</p>

  <div class="mt-5 max-w-[200px] pb-3">
    <shared-form-select
      [label]="'Speaker Mode'"
      [data]="modeOptions"
      [showLabel]="true"
      [selectedOption]="selectedMode"
      [controlName]="modeOptionForm"
      (selectionChange)="handleChangeMode($event)"
    ></shared-form-select>
  </div>

  <div class="max-w-[200px] pb-3" *ngIf="selectedMode==='1'">
    <shared-form-select
      [label]="'Volume Level'"
      [data]="skillOptions"
      [showLabel]="true"
      [selectedOption]="selectedSkill"
      [controlName]="skillOptionForm"
      (selectionChange)="handleChangeSkill($event)"
    ></shared-form-select>
  </div>

</div>
