<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Head light</h3>

  <p class="text-neutral-100 mt-3">Command robot to turn on/off head lights.</p>

  <div class="pb-3">
    <mat-divider class="mt-6 mb-8"></mat-divider>
    <div class="w-full">
      <slide-toggle
        class="mt-2"
        [label]="'Head lights'"
        [description]="'Flash head lights until this job is completed.'"
        (onChange)="handleFloodLightsCheck($event)"
      ></slide-toggle>
    </div>
  </div>

</div>
