<!-- Work around to show defferent drawer width for create patrol job.
Because cannot use condition for create different width directly 
to fuse drawer [classList] input. The first fuse drawer contianer is used
to show the create patrol area job form, and the second fuse drawer is used
to show the others create job form -->
<fuse-drawer
  *ngIf="selectedJobType === 'patrol' || selectedJobType === 'group-patrol'"
  [name]="'jobCreationDrawer'"
  [opened]="toggleJobDrawer"
  [position]="'right'"
  [mode]="'over'"
  [transparentOverlay]="true"
  [fixed]="true"
  [classList]="'patrol-job-creation-drawer'"
  (openedChanged)="onToggleJobDrawer($event)"
>
  <ng-container *ngTemplateOutlet="createJobContent"> </ng-container>
</fuse-drawer>

<fuse-drawer
  *ngIf="selectedJobType !== 'patrol' && selectedJobType !== 'group-patrol'"
  [name]="'jobCreationDrawer'"
  [opened]="toggleJobDrawer"
  [position]="'right'"
  [mode]="'over'"
  [transparentOverlay]="true"
  [fixed]="true"
  [classList]="'job-creation-drawer'"
  (openedChanged)="onToggleJobDrawer($event)"
>
  <ng-container *ngTemplateOutlet="createJobContent"> </ng-container>
</fuse-drawer>

<ng-template #createJobContent>
  <div class="container relative w-full">
    <!-- Content -->
    <div class="flex w-full">
      <div class="w-1/3 border-r bg-neutral-650 border-r-neutral-400">
        <!-- Job List -->
        <ng-container *ngIf="typeCreateJob === 'specific-robot'">
          <div
            *ngIf="robot"
            class="relative"
            [ngClass]="{
              'p-4': typeCreateJob === 'specific-robot'
            }"
          >
            <div class="flex justify-between">
              <div class="flex items-center">
                <h2 class="text-lg">
                  {{ robot?.name }}
                </h2>
              </div>
            </div>

            <!-- <div class="w-full h-20" *ngIf="robot.state === 5"></div> -->
            <!-- <div
              class="absolute left-0 right-0 flex w-full"
              [ngClass]="{
                'top-18': robot.status !== 2,
                'top-14': robot.status === 2
              }"
              *ngIf="robot.state === 5"
            >
              <robot-detail-banner class="w-full"></robot-detail-banner>
            </div> -->
            <!-- <div class="flex flex-col mt-3 mb-5" *ngIf="robot?.state !== 5"> -->
            <div class="flex flex-col mt-3 mb-5">
              <!-- Status -->
              <robot-status [robot]="robot"></robot-status>

              <div
                class="flex flex-row items-center gap-2 mt-2 text-neutral-100"
                *ngIf="robot?.status === 2"
              >
                <mat-icon
                  class="justify-start scale-75"
                  svgIcon="mat_solid:access_time"
                ></mat-icon>
                <span>Last Online: </span>
                <span *ngIf="robot?.lastOnlineTime">
                  {{
                    robot?.lastOnlineTime !== null &&
                    robot?.lastOnlineTime.includes('ago')
                      ? robot?.lastOnlineTime
                      : (robot?.lastOnlineTime | robotLastOnline)
                  }}
                </span>
                <span *ngIf="!robot?.lastOnlineTime">-</span>
              </div>
            </div>

            <div
              class="w-full pb-10 overflow-y-scroll job-list"
              *ngIf="jobs.length > 0; else emptyJob"
            >
              <!-- Loops joblist -->

              <div *ngFor="let job of jobs; let i = index">
                <!-- Start of Job Card -->
                <shared-card-job
                  [job]="job"
                  [enableAction]="false"
                  [broadcastState]="
                    job.skills.toLocaleLowerCase().includes('broadcast') ||
                    job.skills.toLocaleLowerCase().includes('tts')
                  "
                  [blinkState]="
                    job.skills.toLocaleLowerCase().includes('blinker')
                  "
                  (updateJob)="updateJob($event)"
                ></shared-card-job>
                <!-- End of Braodcast Card -->
              </div>
            </div>

            <ng-template #emptyJob>
              <div
                class="flex flex-col items-center justify-center w-full h-40"
              >
                <img
                  src="../../../../assets/images/jobs-component/empty-job-list.svg"
                  alt="Empty Job List"
                  class="w-20"
                />
                <div class="mt-5 text-center text-md text-neutral-100">
                  Robot is on standby.<br />
                  Let's get this robot start working!
                </div>
              </div>
            </ng-template>
          </div>

          <div
            *ngIf="!robot && selectedJobType === 'dispatch-robot'"
            class="flex flex-col items-center justify-center gap-6 my-auto h-[calc(100vh-73px)]"
          >
            <img
              src="/assets/images/jobs-component/empty-robot-list.png"
              alt="Empty Robot List"
              class="w-30"
            />
            <div
              class="flex flex-col items-center justify-center text-center gap-2"
            >
              <p class="text-lg text-neutral-50 font-semibold max-w-100">
                No robot selected
              </p>
              <p class="text-base text-neutral-100 font-normal max-w-64">
                Use selection on the right to select one robot to dispatch
              </p>
            </div>
          </div>
        </ng-container>

        <!-- Selected Robot list -->
        <ng-container *ngIf="typeCreateJob === 'multiple-robot'">
          <div
            class="flex flex-col w-full p-4 h-[calc(100vh-140px)] overflow-y-scroll"
          >
            <div class="flex items-center gap-2 text-lg">
              <h2 class="text-lg font-semibold">
                Selected robot{{ robots.length > 1 ? 's' : '' }}
              </h2>
              <span
                class="flex items-center justify-center w-6 h-5 text-base font-semibold text-center rounded bg-neutral-400"
                >{{ robots.length }}</span
              >
            </div>
            <card-robot-selection
              cardSize="small"
              [layoutWithRobots]="layoutWithRobots"
              [layoutId]="layoutId"
              [siteId]="siteId"
              (selectionChange)="removeRobotSelection($event)"
            ></card-robot-selection>
          </div>
          <div class="flex items-center justify-center w-full">
            <button
              class="w-full flex items-center justify-center p-4 h-[68px] border-t border-t-neutral-400 text-lg font-medium text-neutral-50"
              (click)="selectMoreRobots(selectedSkill)"
            >
              <mat-icon
                class="mr-2 text-xl font-normal text-neutral-50"
                svgIcon="mat_solid:add"
              ></mat-icon>
              Select more robots
            </button>
          </div>
        </ng-container>
      </div>

      <!-- Job creation field -->
      <div class="w-2/3">
        <!-- Header -->
        <div class="w-full p-4 flex border-b-[1px] border-neutral-900">
          <div class="w-1/2">
            <h3
              class="text-2xl text-white"
              [ngClass]="{
                'whitespace-nowrap': selectedJobType === 'dispatch-robot'
              }"
            >
              {{
                selectedJobType === 'dispatch-robot'
                  ? 'Emergency Dispatch'
                  : 'Add a new job'
              }}
            </h3>
          </div>
          <div class="flex justify-end w-1/2">
            <button mat-icon-button class="scale-75" (click)="handleClose()">
              <mat-icon svgIcon="mat_solid:close"></mat-icon>
            </button>
          </div>
        </div>

        <div class="px-6 pt-4 pb-10 overflow-y-scroll job-content">
          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-GO-CHARGE' &&
              selectedJobType === 'charge' &&
              robot
            "
          >
            <app-go-charge
              [robot]="robot"
              [robots]="robots"
              [layoutId]="robot?.layoutId"
              [robotSkills]="robotSkills"
              [jobs]="jobs"
              (closeDrawer)="handleClose()"
              [events]="eventsSubject.asObservable()"
            ></app-go-charge>
          </div>
          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-BLINKER' &&
              selectedJobType === 'blinker'
            "
          >
            <app-blinking-light
              [robot]="robot"
              [robots]="robots"
              [layoutId]="robot?.layoutId"
              [robotSkills]="robotSkills"
              (closeDrawer)="handleClose()"
              [events]="eventsSubject.asObservable()"
            ></app-blinking-light>
          </div>

          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-LIGHT' &&
              selectedJobType === 'floodlight'
            "
          >
            <app-flood-light
              [robot]="robot"
              [robots]="robots"
              [layoutId]="robot?.layoutId"
              [robotSkills]="robotSkills"
              (closeDrawer)="handleClose()"
              [events]="eventsSubject.asObservable()"
            ></app-flood-light>
          </div>

          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-GOTO' &&
              selectedJobType === 'goto'
            "
          >
            <goto-job-creation
              [robot]="robot"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></goto-job-creation>
          </div>
          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-BROADCAST' &&
              selectedJobType === 'broadcast'
            "
          >
            <broadcast-job-creation
              [robot]="robot"
              [robots]="robots"
              [isBlinker]="true"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [skill]="selectedSkill"
              [events]="eventsSubject.asObservable()"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></broadcast-job-creation>
          </div>
          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-GOTO' &&
              selectedJobType === 'patrol'
            "
          >
            <patrol-job-creation
              [robot]="robot"
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></patrol-job-creation>
          </div>
          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-TELECONFERENCE' &&
              selectedJobType === 'calling'
            "
          >
            <app-teleconference
              [robot]="robot"
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></app-teleconference>
          </div>

          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'VOLUMECONTROL' &&
              selectedJobType === 'volume'
            "
          >
            <app-volumecontrol
              [robot]="robot"
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></app-volumecontrol>
          </div>

          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-CHANGEDISPLAY' &&
              selectedJobType === 'changeDisplay'
            "
          >
            <app-change-display
              [robot]="robot"
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></app-change-display>
          </div>

          <div
            *ngIf="
              selectedSkill &&
              selectedSkill.name === 'RM-CHANGEMESSAGE' &&
              selectedJobType === 'changeMsg'
            "
          >
            <app-change-message
              [robot]="robot"
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></app-change-message>
          </div>


          <div *ngIf="selectedSkill && selectedJobType === 'dispatch-robot'">
            <dispatch-robot-creation
              [robots]="robots"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
              (changeRobotSelection)="selectRobot($event)"
            ></dispatch-robot-creation>
          </div>
          <div *ngIf="selectedSkill && selectedJobType === 'group-patrol'">
            <group-patrol-job-creation
              [robots]="robots"
              [robotSkills]="robotSkills"
              [layoutId]="robot?.layoutId"
              [events]="eventsSubject.asObservable()"
              [skillId]="selectedSkill.id"
              (closeDrawer)="handleClose()"
              (resetType)="resetType()"
            ></group-patrol-job-creation>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div
      class="absolute w-full p-4 flex border-b-[1px] bg-neutral-700 bottom-0 z-50"
    >
      <div class="w-3/4 sm:w-1/2"></div>
      <div class="flex justify-end w-1/4 sm:w-1/2">
        <button
          class="w-24 rounded-md max-h-9 h-9 bg-neutral-700 hover:bg-neutral-600"
          mat-flat-button
          (click)="handleClose()"
        >
          Cancel
        </button>

        <button
          class="w-24 ml-3 rounded-md max-h-9 h-9 hover:bg-primary-800"
          mat-flat-button
          [color]="'primary'"
          (click)="emitEventToChild()"
          [disabled]="isLoading"
        >
          <span *ngIf="!isLoading">
            <!-- {{ selectedJobType === 'dispatch-robot' ? 'Dispatch' : selectedJobType === 'calling'? 'Call': 'Add Job' }} -->
              {{getAddBtnText()}}
          </span>
          <mat-progress-spinner
            *ngIf="isLoading"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<div
  *ngIf="toggleJobDrawer"
  class="fixed top-0 bottom-0 left-0 right-0 z-[300]"
></div>
